import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { iconHelpers } from "../../helpers/index.helpers";

import skillsJSONData from "../../data/skills.json";
import IconsJSONData from "../../data/icons.json";

const Skills = () => {
  return (
    <Container fluid className="container-style">
      <Row className="row-padding pb2">
        <h1 className="header-text">Skills & Experiences</h1>
      </Row>

      <div className="row-padding pb2">
        <h4>Tech Stack</h4>

        <Row xs={2} sm={3} md={5} lg={6} xl={8}>
          {skillsJSONData.techStack.map(({ id, name }) => (
            <React.Fragment key={id}>
              {id !== "schema" ? (
                <Col key={id} className="skills-icon-col">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={(IconsJSONData.icons as any)[id].href}
                    id={`skills-icon-anchor-${id}`}
                    className="skills-icon-anchor"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {iconHelpers.renderIcon(id)}

                      <div style={{ textAlign: "center", marginTop: "0.5rem" }}>
                        <p className="skills-text cursor-pointer">{name}</p>
                      </div>
                    </div>
                  </a>
                </Col>
              ) : null}
            </React.Fragment>
          ))}
        </Row>
      </div>

      <div className="row-padding pb2">
        <h4>Skillset</h4>

        <Row xs={2} sm={3} md={5} lg={6} xl={8}>
          {skillsJSONData.skillset.map(({ id, name }) => (
            <React.Fragment key={id}>
              {id !== "schema" ? (
                <Col className="skills-icon-col">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={(IconsJSONData.icons as any)[id].href}
                    id={`skills-icon-anchor-${id}`}
                    className="skills-icon-anchor"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {iconHelpers.renderIcon(id)}

                      <div style={{ textAlign: "center", marginTop: "0.5rem" }}>
                        <p className="skills-text cursor-pointer">{name}</p>
                      </div>
                    </div>
                  </a>
                </Col>
              ) : null}
            </React.Fragment>
          ))}
        </Row>
      </div>

      <div className="row-padding">
        <h4>Technologies I have been experimenting with</h4>

        <Row
          xs={2}
          sm={3}
          md={5}
          lg={6}
          xl={8}
          style={{ marginBottom: "2rem" }}
        >
          {skillsJSONData["technologies-experimenting"].map(({ id, name }) => (
            <React.Fragment key={id}>
              {id !== "schema" ? (
                <Col className="skills-icon-col">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={(IconsJSONData.icons as any)[id].href}
                    id={`skills-icon-anchor-${id}`}
                    className="skills-icon-anchor"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {iconHelpers.renderIcon(id)}

                      <div style={{ textAlign: "center", marginTop: "0.5rem" }}>
                        <p className="skills-text cursor-pointer">{name}</p>
                      </div>
                    </div>
                  </a>
                </Col>
              ) : null}
            </React.Fragment>
          ))}
        </Row>
      </div>
    </Container>
  );
};

export default Skills;
