import React from "react";
import { Container, Row, Card } from "react-bootstrap";
import { FaGithub } from "react-icons/fa";

// import { useNavigate } from "react-router-dom";

import { iconHelpers } from "../../helpers/index.helpers";
import IconsData from "../../data/icons.json";

const ProgrammingProjects = () => {
  interface ProjectCardData {
    id: string;
    name: string;
    description: string;
    src: string;
    stack: string[];
    github?: string;
    fullWidth?: boolean;
  }

  const ProjectData = {
    completed: [
      {
        id: "onepa-badminton-courts-finder-automation",
        name: "OnePA Badminton courts finder automation",
        description:
          "Simple automation to find all the available Badminton courts from OnePA CCs in Singapore",
        src: "onepa-badminton-courts-finder-img-code.png",
        stack: ["python", "computer-automations", "json", "regex"],
        github:
          "https://github.com/Jarrettgohh/Onepa-Badminton-Courts-Finder-Python",
        fullWidth: true,
      },
      {
        id: "mobile-app-project",
        name: "Mobile app project",
        description:
          "Mobile app project: with features such as map display, basic authentication and chat system",
        src: "Map-App.png",
        stack: [
          "react-native",
          "expo-framework",
          "typescript",
          "nodejs",
          "aws-dynamodb",
          "aws-lambda",
        ],
      },
      {
        id: "python-curl",
        name: "Python cURL",
        description: "Python automation to send HTTP requests using cURL",
        src: "python-curl.png",
        stack: [
          "python",
          "computer-automations",
          "computer-networking",
          "json",
        ],
        github: "https://github.com/Jarrettgohh/python-cURL",
        fullWidth: true,
      },
      {
        id: "sys-tools-bash",
        name: "Bash sys-tools",
        description: "System administration tools using Bash",
        src: "sys-tools-bash.png",
        stack: ["bash", "computer-automations", "regex"],
        github: "https://github.com/Jarrettgohh/sys-tools-bash",
        fullWidth: true,
      },
      {
        id: "http-proxy-server",
        name: "HTTP proxy server",
        description: "A simple HTTP proxy server with C sockets programming",
        src: "http-proxy-server.jpg",
        stack: ["c-language", "computer-networking", "wireshark"],
        github:
          "https://github.com/Jarrettgohh/c-sockets/blob/main/http_proxy.c",
        fullWidth: true,
      },
      {
        id: "http-mitm-attack",
        name: "HTTP MitM attack",
        description: "A simple demonstration of a HTTP MitM attack",
        src: "mitm-http-attack.jpeg",
        stack: ["c-language", "computer-networking"],
        github: "",
        fullWidth: true,
      },
      {
        id: "ssh-power",
        name: "Leveraging the powers of SSH",
        description:
          "SSH features experimentation: tunneling/port forwarding and Secure Copy Protocol (SCP)",
        src: "ssh.webp",
        stack: ["computer-networking", "aws-ec2"],
        github: "",
        fullWidth: true,
      },
    ],
    inProgress: [
      {
        id: "dns-routing",
        name: "DNS routing",
        description: "https://wallpapercave.com/wp/wp10743606.jpg",
        src: "dns.webp",
        stack: ["computer-networking"],
        github: "",
        fullWidth: true,
      },
    ],
  };

  const renderProjectCard = (data: ProjectCardData) => {
    return (
      // Double Card components for the margin spacing effect without losing responsive orientation
      // Simple workaround

      <Card
        key={data.id}
        className="project-outer-card"
        onClick={() => handleProjectClick(data.id)}
      >
        <Card className="project-inner-card">
          <Card.Img
            variant="top"
            src={
              window.location.pathname.replace(/\/$/, "") +
              "/images/" +
              data.src
            }
            className="project-card-img"
            style={data?.fullWidth ?? false ? { width: "100%" } : undefined}
          />
          <Card.Body className="project-card-body">
            <Card.Title className="project-card-title">{data.name}</Card.Title>
            <Card.Text className="project-card-text">
              {data.description}
            </Card.Text>
            <Row noGutters={true} className="project-card-github-row">
              {data.github && (
                <>
                  <FaGithub size="1.4rem" />
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data.github ? data.github : undefined}
                    className="project-card-github"
                    onClick={(e) => e.stopPropagation()}
                  >
                    Source Code
                  </a>
                </>
              )}

              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  marginRight: 30,
                }}
              >
                {data.stack.map((tech, index) => (
                  <React.Fragment key={index}>
                    {iconHelpers.renderIcon(tech, {
                      // @ts-ignore-next-line
                      color: IconsData.icons[tech].color,
                      size: "1.3rem",
                    })}
                  </React.Fragment>
                ))}
              </div>
            </Row>
          </Card.Body>
        </Card>
      </Card>
    );
  };

  const handleProjectClick = (projectId: string) => {
    window.open(
      `${window.location.origin}/projects/${projectId}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <Container fluid className="container-style">
      <Row className="row-padding">
        <h1 className="header-text">Computing Projects & Experimentations</h1>
      </Row>

      <div className="pb2">
        <div className="row-padding">
          <h4>Completed</h4>
        </div>

        <Row xs={1} sm={1} md={2} lg={2} xl={3} className="row-padding">
          {ProjectData.completed.map((data) =>
            // Double Card components for the margin spacing effect without losing responsive orientation
            // Simple workaround

            renderProjectCard(data)
          )}
        </Row>

        <div className="pb2">
          <div className="row-padding">
            <h4>In Progress</h4>
          </div>

          <Row xs={1} sm={1} md={2} lg={2} xl={3} className="row-padding">
            {ProjectData.inProgress.map((data) =>
              // Double Card components for the margin spacing effect without losing responsive orientation
              // Simple workaround

              renderProjectCard(data)
            )}
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default ProgrammingProjects;
