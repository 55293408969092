import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import CERTIFICATES from "../../data/certifications.json";

export default function Certifications() {
  return (
    <Container fluid className="container-style pb2">
      <Row className="row-padding pb2">
        <h1 className="header-text">Certifications</h1>
      </Row>

      <Row xs={1} sm={1} md={2} lg={2} xl={2}>
        {CERTIFICATES.data.map((cert, index) => (
          <Col
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <img
              style={{ width: "100%", height: "100%" }}
              src={
                window.location.pathname.replace(/\/$/, "") +
                "/../../images/" +
                cert.url
              }
              aria-label={cert.name}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
}
