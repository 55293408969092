import React, { FC, useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaGithub } from "react-icons/fa";
import { FiLink } from "react-icons/fi";
import Lottie from "react-lottie";
import ReactHTMLParser from "react-html-parser";
import DOMPUrify from "dompurify";

// @ts-ignore-next-line
import TERMINAL_CONSOLE_DISPLAY from "../../data/terminal-console-display.json";

import animationData from "../../assets/i_loading_animation.json";
import ICONS from "../../data/icons.json";
import COLORS from "../../data/colors.json";
import CONTACT_ME from "../../data/contact-me.json";

import { iconHelpers } from "../../helpers/index.helpers";
import TerminalConsoleDisplay from "./terminalConsoleDisplay";

import "../../css/projectPage.css";
import { EmailIcon } from "../icons/general.icons";

interface WorkingExample {
  mainDescription: string;
  sections: [
    {
      header?: string;
      description: string[];
      videos?: string[];
      isMobilePreview?: boolean;
      doNotDisplay?: boolean;
    }
  ];
}

interface Package {
  name: string;
  url: string;
}

interface Video {
  name: string;
  url: string;
}

interface Github {
  name?: string;
  url: string;
}

type Skill = {
  iconId: string;
  description: string;
};

interface SkillsExperiences {
  techStack: Skill[];
  skillset: Skill[];
}

interface Props {
  id: string;
  note?: string;
  name: string;
  about: string[];
  workingExample: WorkingExample;
  howToMitigate?: string[];
  problems?: string[];
  learnt?: string[];
  skillsExperiences: SkillsExperiences;
  packages?: Package[];
  videos?: Video[];
  citations?: {
    url: string;
    description: string;
  }[];
  github?: Github[];
  toOmit?: boolean;
}

const ProjectPage: FC<Props> = ({
  id,
  name,
  note,
  about,
  workingExample,
  howToMitigate,
  problems,
  learnt,
  github,
  citations,
  skillsExperiences,
  toOmit,
}) => {
  const TERMINAL_COMMANDS =
    // @ts-ignore-next-line
    typeof TERMINAL_CONSOLE_DISPLAY["projects"][id] !== "undefined"
      ? // @ts-ignore-next-line
        TERMINAL_CONSOLE_DISPLAY["projects"][id]
      : null;

  const httpProxyServerTerminalRef = useRef(null);
  const httpMitmProxyTerminalRef = useRef(null);
  const sshTunnelingTerminalRef = useRef(null);

  const topRow = useRef<HTMLDivElement | null>(null);

  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [shouldLoadVideo, setShouldLoadVideo] = useState(false);

  const renderTerminalDisplay = (index: number) => {
    const displaySectionIndex = index;

    let TERMINAL_OUTPUT: any[];

    if (TERMINAL_COMMANDS) {
      TERMINAL_OUTPUT =
        TERMINAL_COMMANDS["display-sections"][displaySectionIndex]["output"];
    } else {
      return null;
    }

    switch (id) {
      case "http-proxy-server": {
        return (
          <TerminalConsoleDisplay
            id="http-proxy-server"
            displaySectionIndex={displaySectionIndex}
            numOfTerminal={2}
            ref={httpProxyServerTerminalRef}
            callbackFnFromTerminalJSON={() => {
              if (
                typeof httpProxyServerTerminalRef.current === "undefined" ||
                !httpProxyServerTerminalRef.current ||
                !TERMINAL_OUTPUT[0]
              ) {
                return;
              }

              // @ts-ignore-next-line
              httpProxyServerTerminalRef.current.insertTerminalOutput(
                1,
                TERMINAL_OUTPUT[0],
                async () => {
                  await new Promise((res) => setTimeout(res, 1000));

                  // @ts-ignore-next-line
                  httpProxyServerTerminalRef.current.insertTerminalOutput(
                    2,
                    TERMINAL_OUTPUT[1]
                  );

                  // @ts-ignore-next-line
                  httpProxyServerTerminalRef.current.terminateProgram(2, 2);
                }
              );
            }}
          />
        );
      }

      case "http-mitm-attack":
        return (
          <>
            <TerminalConsoleDisplay
              id="http-mitm-attack"
              displaySectionIndex={displaySectionIndex}
              numOfTerminal={3}
              videoDisplayURI={"/../../videos/mitm-proxy-take-4.mp4"}
              shouldStartVideoOnPlayButtonClick={false}
              ref={httpMitmProxyTerminalRef}
              callbackFnFromTerminalJSON={() => {
                // @ts-ignore-next-line
                httpMitmProxyTerminalRef.current.startVideo();

                // @ts-ignore-next-line
                httpMitmProxyTerminalRef.current.startCountdownCallback(
                  9,
                  () => {
                    //
                    //  HANDLE START RUNNING ARPSPOOF
                    //
                    // @ts-ignore-next-line
                    httpMitmProxyTerminalRef.current.executeCommand(3, 3);

                    //
                    // OUTPUT proxy log
                    //

                    // @ts-ignore-next-line
                    httpMitmProxyTerminalRef.current.startCountdownCallback(
                      3,
                      () => {
                        // @ts-ignore-next-line
                        httpMitmProxyTerminalRef.current.insertTerminalOutput(
                          2,
                          TERMINAL_OUTPUT[0]
                        );
                      }
                    );

                    // @ts-ignore-next-line
                    httpMitmProxyTerminalRef.current.startCountdownCallback(
                      7,
                      () => {
                        // @ts-ignore-next-line
                        httpMitmProxyTerminalRef.current.insertTerminalOutput(
                          2,
                          TERMINAL_OUTPUT[0]
                        );
                      }
                    );
                  }
                );

                // @ts-ignore-next-line
                httpMitmProxyTerminalRef.current.startCountdownCallback(
                  18,
                  () => {
                    // @ts-ignore-next-line
                    httpMitmProxyTerminalRef.current.insertTerminalOutput(
                      2,
                      TERMINAL_OUTPUT[1]
                    );
                  }
                );

                // @ts-ignore-next-line
                httpMitmProxyTerminalRef.current.startCountdownCallback(
                  19,
                  () => {
                    // @ts-ignore-next-line
                    httpMitmProxyTerminalRef.current.insertTerminalOutput(
                      2,
                      TERMINAL_OUTPUT[2]
                    );
                  }
                );

                // @ts-ignore-next-line
                httpMitmProxyTerminalRef.current.startCountdownCallback(
                  22,
                  () => {
                    // @ts-ignore-next-line
                    httpMitmProxyTerminalRef.current.insertTerminalOutput(
                      2,
                      TERMINAL_OUTPUT[3]
                    );
                  }
                );

                // @ts-ignore-next-line
                httpMitmProxyTerminalRef.current.startCountdownCallback(
                  24,
                  () => {
                    // @ts-ignore-next-line
                    httpMitmProxyTerminalRef.current.insertTerminalOutput(
                      2,
                      TERMINAL_OUTPUT[4]
                    );
                  }
                );
              }}
            />
          </>
        );

      case "ssh-power": {
        const retrieveSSHPowerNumOfTerminal = (index: number) => {
          switch (index) {
            // direct tunnel
            case 0:
              return 3;

            // dynamic tunnel
            case 1:
              return 4;

            // reverse tunnel
            // SCP
            case 2:
            case 3:
              return 2;

            default:
              return 0;
          }
        };

        return (
          <TerminalConsoleDisplay
            id="ssh-power"
            displaySectionIndex={displaySectionIndex}
            numOfTerminal={retrieveSSHPowerNumOfTerminal(displaySectionIndex)}
            ref={sshTunnelingTerminalRef}
            callbackFnFromTerminalJSON={() => {
              const retrieveInsertTerminalOutputValues = (index: number) => {
                switch (index) {
                  case 0:
                    return [1, 0];

                  case 1:
                    return [2, 0];

                  default:
                    return null;
                }
              };

              const insertTerminalOutputValues =
                retrieveInsertTerminalOutputValues(index);

              if (!insertTerminalOutputValues) return;

              // @ts-ignore-next-line
              sshTunnelingTerminalRef.current.insertTerminalOutput(
                insertTerminalOutputValues[0],
                TERMINAL_OUTPUT[insertTerminalOutputValues[1]]
              );
            }}
          />
        );
      }

      default:
        return null;
    }
  };

  useEffect(() => {
    // To make sure page starts from the top
    // topRow.current?.scrollIntoView({ block: "end", behavior: "instant" });

    const delayVideoLoading = async () => {
      // @ts-ignore
      await new Promise((res) => setTimeout(res, 1000));
      setShouldLoadVideo(true);
    };
    delayVideoLoading();
  }, []);

  const formatHTMLString = (htmlString: string) => {
    //
    // $<url>&&<text>$
    // -> <a href={url} target="_blank" rel="noreferrer">{text}</a>

    // regex pattern: $url&&text$
    // ${!!}/url-path&&text$ - resolves {!!} to window.location.origin
    const url_regex =
      /\$(\/|\.|:|-|_|\(|\)|#|~|\d|\w|\s|{|}|!)*&&(\/|\.|:|-|_|\(|\)|\d|\w|\s|')*\$/gi;
    const url_matches = htmlString.match(url_regex) ?? [];

    let formatted = htmlString;

    for (let i = 0; i < url_matches.length; i++) {
      const url_match = url_matches[i];
      const matches = url_match.replace(/\$/g, "").split("&&");

      let url;

      if (/^\$/.exec(matches[0])) {
        // const url =  /^\$/.exec matches[0]) ? :matches[0];
      } else {
        url = matches[0];
      }

      const text = matches[1];

      if (typeof url === "undefined") continue;

      const placeholderRegex = /{!!}/g;
      const placeholderMatches = url.match(placeholderRegex);

      if (placeholderMatches && placeholderMatches.length > 0) {
        const placeholder = placeholderMatches[0];

        switch (placeholder) {
          case "{!!}":
            url = url.replace(placeholderRegex, window.location.origin);
            break;

          default:
            break;
        }
      }

      const anchorTag = `<a target="_blank" rel="noopener noreferrer" href="${url}">${text}</a>`;

      formatted = formatted.replace(url_match, anchorTag);
    }

    //
    // #text# -> <b>{text}</b>
    //

    // regex pattern: #text#
    const bold_regex = /#((?!#).)*#/gi; // /#(\/|\.|:|-|_|\d|\w|\s)*#/gi;
    const bold_matches = htmlString.match(bold_regex);

    bold_matches?.forEach((bold_match) => {
      const replace = bold_match.replace(/^#/g, "<b>").replace(/#$/g, "</b>");

      formatted = formatted.replace(bold_match, replace);
    });

    //
    // ^text^ -> <code>{text}</code>
    //

    // regex pattern: ^text^
    const code_regex = /\^((?!\^).)*\^/gi; // /\^(\/|\.|:|-|_|\d|\w|\s)*\^/gi
    const code_matches = htmlString.match(code_regex);

    code_matches?.forEach((code_match) => {
      const replace = code_match
        .replace(
          /^\^/g,
          '<code class="p-small" style="color: #e83e8c; font-size: 1.2rem">'
        )
        .replace(/\^$/g, "</code>");

      formatted = formatted.replace(code_match, replace);
    });

    //
    // `text` -> <code className="highlight">{text}</code>
    //

    // regex pattern: `text`
    const hightlight_regex = /`((?!`).)*`/gi; // /`(\/|\.|:|-|_|\d|\w|\s)*`/gi
    const hightlight_matches = htmlString.match(hightlight_regex);

    hightlight_matches?.forEach((hightlight_match) => {
      const replace = hightlight_match
        .replace(/^`/g, '<code class="highlight">')
        .replace(/`$/g, "</code>");

      formatted = formatted.replace(hightlight_match, replace);
    });

    return formatted;
  };

  return (
    <Container fluid className="project-container-style">
      {/* TOP ROW AS A WORKAROUND, BECAUSE PAGE ALWAYS NOT AT TOP ON RENDER */}
      <Row ref={topRow} />
      {/* PROJECT NAME */}
      <Row
        className="header-padding"
        style={{ borderBottom: "2px solid silver" }}
      >
        <h1 className="header-text-lg" style={{ color: COLORS["main-1"] }}>
          {name}
        </h1>

        {/* GITHUB SOURCE CODE */}
        {github && (
          <Row noGutters={true} className="project-github-row">
            {github.map((gitItem, index) => {
              return (
                <div key={index}>
                  <FaGithub size="1.4rem" />

                  <a
                    key={index}
                    target="_blank"
                    rel="noreferrer"
                    href={gitItem.url}
                    className="project-card-github text-style-small"
                  >
                    {gitItem.name ? gitItem.name : "Source Code"}
                  </a>
                </div>
              );
            })}
          </Row>
        )}

        {note && (
          <div>
            <h6 style={{ margin: 20 }}>
              <strong>{`P.S ${note}`}</strong>
            </h6>
          </div>
        )}
      </Row>

      {toOmit ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <h3>In progress</h3>
        </div>
      ) : (
        <>
          {/* ABOUT THE PROJECT */}
          <Row className="header-padding">
            <h1 className="header-text">About The Project</h1>
          </Row>

          <Row className="row-padding" style={{ flexDirection: "column" }}>
            <>
              {about?.map((paragraph, index) => (
                <p key={index} className="text-style p0">
                  {ReactHTMLParser(
                    DOMPUrify.sanitize(formatHTMLString(paragraph), {
                      ADD_ATTR: ["target", "class"],
                    })
                  )}
                </p>
              ))}
            </>
          </Row>

          {/* WORKING EXAMPLES */}
          <Row className="header-padding">
            <h1 className="header-text">Working Examples</h1>
          </Row>

          {typeof workingExample.mainDescription !== "undefined" ? (
            <div className="row-padding row">
              <p className="text-style p0">
                {ReactHTMLParser(
                  DOMPUrify.sanitize(
                    formatHTMLString(workingExample.mainDescription),
                    {
                      ADD_ATTR: ["target", "class"],
                    }
                  )
                )}
              </p>
            </div>
          ) : null}

          {workingExample.sections.map(
            (
              { description, videos, isMobilePreview, header },
              workingExampleIndex
            ) => (
              <React.Fragment key={workingExampleIndex}>
                {typeof header !== "undefined" ? (
                  <div className="header-padding row">
                    <h1 className="header-text-mini">{header}</h1>
                  </div>
                ) : null}

                <Row
                  className="row-padding"
                  key={workingExampleIndex}
                  style={{ flexDirection: "column" }}
                >
                  {description.map((paragraph, index) => (
                    <p key={index} className="text-style p0">
                      {ReactHTMLParser(
                        DOMPUrify.sanitize(formatHTMLString(paragraph), {
                          ADD_ATTR: ["target", "class"],
                        })
                      )}
                    </p>
                  ))}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: isMobilePreview ? "row" : "column",
                    }}
                  >
                    {videos?.map((video, index) => (
                      <div
                        key={index}
                        className={
                          isMobilePreview
                            ? "mobile-video-player-container"
                            : "video-player-container mb2"
                        }
                        style={{ position: "relative" }}
                      >
                        <video
                          className="video-player"
                          style={{
                            display: isVideoLoading ? "none" : "unset",
                          }}
                          src={
                            shouldLoadVideo
                              ? window.location.pathname.replace(/\/$/, "") +
                                video
                              : undefined
                          }
                          onLoadedData={() => setIsVideoLoading(false)}
                          autoPlay
                          muted
                          loop
                          controls={isMobilePreview ? false : true}
                        />

                        <div
                          style={{
                            left: 0,
                            top: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Lottie
                            options={{
                              animationData,
                            }}
                            height="30rem"
                            width="30rem"
                            style={{
                              display: isVideoLoading ? "unset" : "none",
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </Row>

                {/* TERMINAL TYPING EFFECT */}
                {renderTerminalDisplay(workingExampleIndex)}
              </React.Fragment>
            )
          )}

          {/* HOW TO MITIGATE IT */}
          {howToMitigate ? (
            <>
              <Row className="header-padding">
                <h1 className="header-text">How to mitigate it</h1>
              </Row>

              <Row className="row-padding" style={{ paddingBottom: "1rem" }}>
                {howToMitigate?.map((mitigate_paragraph, index) => (
                  <p key={index} className="text-style">
                    {ReactHTMLParser(
                      DOMPUrify.sanitize(formatHTMLString(mitigate_paragraph), {
                        ADD_ATTR: ["target", "class"],
                      })
                    )}
                  </p>
                ))}
              </Row>
            </>
          ) : null}

          {/* PROBLEMS FACED */}
          {
            <>
              {problems ? (
                <>
                  <Row className="header-padding">
                    <h1 className="header-text">Problems/Challenges Faced</h1>
                  </Row>

                  <>
                    <Row
                      className="row-padding"
                      style={{ paddingBottom: "1rem" }}
                    >
                      {problems?.map((problem_paragraph, index) => (
                        <p key={index} className="text-style">
                          {ReactHTMLParser(
                            DOMPUrify.sanitize(
                              formatHTMLString(problem_paragraph),
                              {
                                ADD_ATTR: ["target", "class"],
                              }
                            )
                          )}
                        </p>
                      ))}
                    </Row>
                  </>
                </>
              ) : null}

              {/* WHAT I HAVE LEARNT */}
              <Row className="header-padding">
                <h1 className="header-text">What I Have Learnt</h1>
              </Row>
              <Row className="row-padding">
                {learnt?.map((learnt_paragraph, index) => (
                  <p key={index} className="text-style">
                    {ReactHTMLParser(
                      DOMPUrify.sanitize(formatHTMLString(learnt_paragraph), {
                        ADD_ATTR: ["target", "class"],
                      })
                    )}
                  </p>
                ))}
              </Row>
            </>
          }

          {/* SKILLS & EXPERIENCES */}
          <Row className="header-padding pb2">
            <h1 className="header-text-mini" style={{ fontStyle: "italic" }}>
              Skills & Experiences
            </h1>
          </Row>

          {skillsExperiences.techStack.length > 0 ? (
            <div>
              <div className="row-padding">
                <h4>Tech Stack</h4>
              </div>

              {skillsExperiences.techStack.map(
                ({ iconId, description }, index) => (
                  <Row key={index} className="pb2">
                    <Col md={2} lg={2} xl={2}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={(ICONS.icons as any)[iconId].href}
                        >
                          {iconHelpers.renderIcon(iconId, {
                            color: (ICONS.icons as any)[iconId].color,
                          })}
                        </a>

                        <div
                          style={{
                            display: "flex",
                            textAlign: "center",
                            marginTop: "0.5rem",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "1.2rem",
                              color: (ICONS.icons as any)[iconId].color,
                            }}
                          >
                            {(ICONS.icons as any)[iconId].name}
                          </p>
                        </div>
                      </div>
                    </Col>

                    <Col md={10} lg={10} xl={10}>
                      <pre
                        style={{
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                          wordWrap: "break-word",
                        }}
                      >
                        <p className="text-style">
                          {ReactHTMLParser(
                            DOMPUrify.sanitize(formatHTMLString(description), {
                              ADD_ATTR: ["target"],
                            })
                          )}
                        </p>
                      </pre>
                    </Col>
                  </Row>
                )
              )}
            </div>
          ) : null}

          {skillsExperiences.skillset.length > 0 ? (
            <div>
              <div className="row-padding">
                <h4>Skillset</h4>
              </div>

              {skillsExperiences.skillset.map(
                ({ iconId, description }, index) => (
                  <Row key={index} className="pb2">
                    <Col md={2} lg={2} xl={2}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={(ICONS.icons as any)[iconId].href}
                        >
                          {iconHelpers.renderIcon(iconId, {
                            color: (ICONS.icons as any)[iconId].color,
                          })}
                        </a>
                        <div
                          style={{
                            display: "flex",
                            textAlign: "center",
                            marginTop: "0.5rem",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "1.2rem",
                              color: (ICONS.icons as any)[iconId].color,
                            }}
                          >
                            {(ICONS.icons as any)[iconId].name}
                          </p>
                        </div>
                      </div>
                    </Col>

                    <Col md={10} lg={10} xl={10}>
                      <pre
                        className="text-style"
                        style={{
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                          wordWrap: "break-word",
                        }}
                      >
                        <p>
                          {ReactHTMLParser(
                            DOMPUrify.sanitize(formatHTMLString(description), {
                              ADD_ATTR: ["target"],
                            })
                          )}
                        </p>
                      </pre>
                    </Col>
                  </Row>
                )
              )}
            </div>
          ) : null}

          {(citations ?? []).length > 0 ? (
            <div className="row-padding">
              <h4>Citations</h4>

              {citations?.map(({ url, description }, index) => (
                <Row key={index} className="row-padding">
                  <FiLink size="1.4rem" color="gray" />
                  <div style={{ margin: 1.2 }} />

                  <h6>{description}: </h6>

                  <a
                    className="text-style-small"
                    style={{ cursor: "pointer", marginLeft: 8 }}
                    target="_blank"
                    rel="noreferrer"
                    href={url}
                  >
                    {url}
                  </a>
                </Row>
              ))}
            </div>
          ) : null}

          <div style={{ borderTop: "2px solid silver", marginBottom: "2rem" }}>
            <div className="header-padding row">
              <h2 style={{ color: COLORS["main-2"], fontWeight: "bold" }}>
                Contact Me
              </h2>
            </div>

            <div className="row-padding row">
              <p className="text-style">{CONTACT_ME.description}</p>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <a href={`mailto:${CONTACT_ME.mailtoAddr}`}>
                  <EmailIcon
                    size={30}
                    color={COLORS["main-light"]}
                    className="email-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default ProjectPage;
