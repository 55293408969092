import { VscDebugStart, VscDebugRestart } from "react-icons/vsc";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";

interface IconProps {
  size?: number;
  color?: string;
  className?: string;
}

const StartIcon = ({ size, color, className }: IconProps) => {
  return <VscDebugStart size={size} style={{ color }} className={className} />;
};

const TrashIcon = ({ size, color, className }: IconProps) => {
  return <FaRegTrashAlt size={size} style={{ color }} className={className} />;
};

const RestartIcon = ({ size, color, className }: IconProps) => {
  return (
    <VscDebugRestart size={size} style={{ color }} className={className} />
  );
};

const EmailIcon = ({ size, color, className }: IconProps) => {
  return <MdOutlineEmail size={size} style={{ color }} className={className} />;
};

export { StartIcon, RestartIcon, TrashIcon, EmailIcon };
