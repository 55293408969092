import { Navbar, Nav } from "react-bootstrap";
import { FaGithub, FaStackOverflow } from "react-icons/fa";
import { SiTryhackme } from "react-icons/si";

import URLEndpointsData from "../data/url-endpoints.json";
import Version from "../data/version.json";

const NavbarComponent = () => {
  return (
    <Navbar expand="lg" variant="dark" className="navbar-style">
      <Navbar.Brand href={URLEndpointsData.main} className="navbar-brand">
        Jarrett Goh Xiang Zheng
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link
            target="_blank"
            rel="noreferrer"
            href="https://stackoverflow.com/users/14631246/jarrett?tab=profile"
            className="navbar-link"
          >
            <FaStackOverflow style={{ marginRight: 4 }} />
            Stackoverflow
          </Nav.Link>
          <Nav.Link
            target="_blank"
            rel="noreferrer"
            href="https://github.com/Jarrettgohh"
            className="navbar-link"
          >
            <FaGithub style={{ marginRight: 4 }} />
            Github
          </Nav.Link>
          <Nav.Link
            target="_blank"
            rel="noreferrer"
            href="https://tryhackme.com/p/JarrettSec"
            className="navbar-link"
          >
            <SiTryhackme style={{ marginRight: 4 }} />
            TryHackMe
          </Nav.Link>
        </Nav>

        <div style={{ marginTop: "1.2rem" }}>
          <p>
            {Version.updated} {Version.version}
          </p>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
