import {
  FaReact,
  FaNodeJs,
  FaAws,
  FaLinux,
  FaNetworkWired,
} from "react-icons/fa";
import {
  SiGnubash,
  SiAmazondynamodb,
  SiAwslambda,
  SiAmazonec2,
  SiExpo,
  SiVim,
  SiWireshark,
} from "react-icons/si";
import {
  TbBrandTypescript,
  TbBrandPython,
  TbSettingsAutomation,
  TbCloudComputing,
  TbServerBolt,
} from "react-icons/tb";
import { BsRegex } from "react-icons/bs";
import { RiUbuntuLine } from "react-icons/ri";
import { VscJson } from "react-icons/vsc";
import { TbHexagonLetterC } from "react-icons/tb";

interface Options {
  color?: string;
  size?: any;
}

const renderIcon = (id: string, options?: Options) => {
  const { color, size = "6rem" } = options || {};

  switch (id) {
    case "python":
      return (
        <TbBrandPython className="skills-icon" size={size} style={{ color }} />
      );

    case "expo-framework":
      return <SiExpo className="skills-icon" size={size} style={{ color }} />;

    case "react-native":
      return <FaReact className="skills-icon" size={size} style={{ color }} />;

    case "typescript":
      return (
        <TbBrandTypescript
          id="tb-brand-typescript"
          className="skills-icon"
          size={size}
          style={{
            color,
          }}
        />
      );

    case "nodejs":
      return (
        <FaNodeJs
          id="fa-nodejs"
          className="skills-icon"
          size={size}
          style={{
            color,
          }}
        />
      );

    case "bash":
      return (
        <SiGnubash
          id="si-gnu-bash"
          className="skills-icon"
          size={size}
          style={{
            color,
          }}
        />
      );

    case "linux":
      return (
        <FaLinux
          id="fa-linux"
          className="skills-icon"
          size={size}
          style={{
            color,
          }}
        />
      );

    case "ubuntu":
      return (
        <RiUbuntuLine
          id="ri-ubuntu-line"
          className="skills-icon"
          size={size}
          style={{
            color,
          }}
        />
      );

    case "aws":
      return (
        <FaAws
          id="fa-aws"
          className="skills-icon"
          size={size}
          style={{
            color,
          }}
        />
      );

    case "aws-dynamodb":
      return (
        <SiAmazondynamodb
          className="skills-icon"
          size={size}
          style={{
            color,
          }}
        />
      );

    case "aws-lambda":
      return (
        <SiAwslambda
          id="aws-lambda"
          className="skills-icon"
          size={size}
          style={{
            color,
          }}
        />
      );

    case "aws-ec2":
      return (
        <SiAmazonec2
          id="si-aws-ec2"
          className="skills-icon"
          size={size}
          style={{
            color,
          }}
        />
      );

    case "regex":
      return (
        <BsRegex
          id="bs-regex"
          className="skills-icon"
          size={size}
          style={{
            color,
          }}
        />
      );

    case "computer-automations":
      return (
        <TbSettingsAutomation
          id="tb-settings-automation"
          className="skills-icon"
          size={size}
          style={{
            color,
          }}
        />
      );

    case "computer-networking":
      return (
        <FaNetworkWired
          id="fa-network-wired"
          className="skills-icon"
          size={size}
          style={{
            color,
          }}
        />
      );

    case "cloud-computing":
      return (
        <TbCloudComputing
          id="tb-cloud-computing"
          className="skills-icon"
          size={size}
          style={{
            color,
          }}
        />
      );

    case "json":
      return (
        <VscJson
          id="vsc-json"
          className="skills-icon"
          size={size}
          style={{
            color,
          }}
        />
      );

    case "vim":
      return (
        <SiVim
          id="si-vim"
          className="skills-icon"
          size={size}
          style={{
            color,
          }}
        />
      );

    case "wireshark":
      return (
        <SiWireshark
          id="si-wireshark"
          className="skills-icon"
          size={size}
          style={{
            color,
          }}
        />
      );

    case "c-language":
      return (
        <TbHexagonLetterC
          id="tb-letterc"
          className="skills-icon"
          size={size}
          style={{
            color,
          }}
        />
      );

    //
    // SiBurpsuite, SiPortswigger not found
    //
  }
};

export { renderIcon };
