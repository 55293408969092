import HelloWorld from "./main/helloWorld";
import AboutMe from "./main/aboutMe";
import ProgrammingProjects from "./main/programmingProjects";
import Skills from "./main/skills";
import Citations from "./main/citations";
import Certifications from "./main/certifications";

const MainPage = () => {
  return (
    <>
      {/* HELLO WORLD*/}
      <HelloWorld />

      {/* ABOUT ME */}
      <AboutMe />

      {/* PROGRAMMING PROJECTS */}
      <ProgrammingProjects />

      {/* Skills & Experiences */}
      <Skills />

      {/* Certifications */}
      <Certifications />

      {/* CITATIONS */}
      <Citations />
    </>
  );
};

export default MainPage;
