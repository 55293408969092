import { Container, Row, Col } from "react-bootstrap";

import data from "../../data/main-page.json";

const HelloWorld = () => {
  return (
    <Container fluid className="container-style pb2">
      <Row className="header-padding">
        <h1 className="header-text pb1">Hello World!</h1>
      </Row>

      <Row className="row-padding">
        <Col
          style={{
            padding: 0,
            width: "100%",
            display: "flex",
          }}
        >
          <pre className="text-style">{data.helloWorld}</pre>
        </Col>
      </Row>

      <Row
        // className="row-padding"
        style={{ paddingTop: "3rem" }}
      >
        <Col
          lg={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0.4rem",
            paddingLeft: "1rem",
          }}
        >
          <img
            alt="ME WORKING"
            src={window.location.pathname + "images/me_doing_work.jpeg"}
            style={{
              width: "100%",
              height: "30rem",
              borderRadius: "2.5%",
              objectFit: "cover",
              borderWidth: "0.05rem",
              borderColor: "#6E2E2E",
              borderStyle: "solid",
            }}
          />
        </Col>

        <Col
          lg={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0.4rem",
            paddingRight: "1rem",
          }}
        >
          <img
            alt="ME READING BOOK"
            src={window.location.pathname + "images/me_reading_book_2.jpeg"}
            style={{
              width: "100%",
              height: "30rem",
              borderRadius: "2.5%",
              objectFit: "cover",
              borderWidth: "0.05rem",
              borderColor: "#6E2E2E",
              borderStyle: "solid",
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default HelloWorld;
