import React from "react";

export default function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "50%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h2>404 NOT FOUND</h2>
    </div>
  );
}
