import { Container, Row } from "react-bootstrap";

import data from "../../data/main-page.json";

const AboutMe = () => {
  return (
    <Container fluid className="container-style">
      <Row className="header-padding">
        <h1 className="header-text">About Me</h1>
      </Row>

      {data.aboutMe.map((paragraph, index) => (
        <Row key={index} className="row-padding">
          <p className="text-style">{paragraph}</p>
        </Row>
      ))}
    </Container>
  );
};

export default AboutMe;
