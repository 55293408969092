import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import NavbarComponent from "./components/navbarComponent";
import MainPage from "./components/mainPage";
import ProjectPage from "./components/common/projectPage";
import NotFound from "./components/notFound";

import programmingProjectsJsonData from "../src/data/programming-projects.json";

import "./css/main.css";
import "./css/constant.css";

function App() {
  // useEffect(() => {
  //   window.location.assign("https://jarrett.gohxiangzheng.com");
  // }, []);

  return (
    // To be refactored

    <>
      {/* Navbar Component */}
      <NavbarComponent />

      <BrowserRouter>
        <Routes>
          {programmingProjectsJsonData.projects.map(
            ({
              id,
              name,
              about,
              workingExample,
              howToMitigate,
              problems,
              learnt,
              github,
              route,
              skillsExperiences,
              citations,
              toOmit,
            }) =>
              id !== "schema" ? (
                <Route
                  key={id}
                  path={`/projects${route.path}`}
                  element={
                    <ProjectPage
                      id={id}
                      name={name}
                      about={about}
                      //@ts-ignore
                      workingExample={workingExample}
                      howToMitigate={howToMitigate}
                      problems={problems}
                      learnt={learnt}
                      github={github}
                      citations={citations}
                      skillsExperiences={skillsExperiences}
                      toOmit={toOmit}
                    />
                  }
                />
              ) : null
          )}

          {/* MAIN PAGE */}
          <Route path="/" element={<MainPage />} />

          {/* NOT FOUND */}
          <Route path="/not-found" element={<NotFound />} />

          {/* REDIRECT TO NOT-FOUND */}
          <Route path="*" element={<Navigate to="/not-found" replace />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
