import React, { forwardRef, useCallback, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Container, Row, Col } from "react-bootstrap";

// @ts-ignore-next-line
import TypewriterClass from "typewriter-effect/dist/core";

import { StartIcon, TrashIcon } from "../icons/index.icons";
import TERMINAL_COMMANDS from "../../data/terminal-console-display.json";
import { stringHelper } from "../../helpers/index.helpers";

import "../../css/terminalConsoleDisplay.css";

interface Props {
  id: string;
  displaySectionIndex: number;
  numOfTerminal: number;
  videoDisplayURI?: string;
  shouldStartVideoOnPlayButtonClick?: boolean;

  // callback as defined from the "shouldExecCallbackOnFinish" set to true in `terminal-console-display.json`
  callbackFnFromTerminalJSON?: () => void;

  //   // callback as defined with a specified timeout - ** STARTS COUNTING FROM onClick of play button
  //   callbackFnFromTimeoutSettings?: {
  //     timeout{
  //       seconds: number,
  // type:"onStartVideo",
  //     };
  //     callbackFn: () => void;
  //   };
}

interface Command {
  input: string;
  output: string;
  isInfiniteOutput?: boolean;
  isProgramRunning?: boolean;
  shouldExecCallbackOnFinish?: boolean;
  shouldPromptForSudoPassword?: boolean;
  shouldPauseExecution?: boolean;
}

//
// ** CURRENT ISSUES:
// 1. Only able to call one switch clause from callbackFnFromTerminalJSON() ("curl-complete") as hardcoded from 'projectPage.tsx'
// -> setting of state in both `projectPage.tsx` and this component itself to keep of callback function indexes causes multiple useCallback-wrapped functions to be re-rendered which causes the useEffect on line 422 (might have changed) to be re-rendered and call the startTypingOnTerminal() function and causes an unexpected effect of the current cursor on the terminal retyping again
// -> need to restructure the function calls and logic itself to prevent inter-dependency of the useCallback functions and useEffect

const defaultTypewriterCursorClass = "Typewriter__cursor";

const defaultCurrentTerminalTyping = 1;

const TerminalConsoleDisplay = forwardRef<void, Props>(
  (
    {
      id,
      displaySectionIndex,
      numOfTerminal,
      videoDisplayURI,
      shouldStartVideoOnPlayButtonClick = true,
      callbackFnFromTerminalJSON,
      // callbackFnFromTimeoutSettings,
    },
    ref
  ) => {
    const videoRef = useRef(null);

    const TERMINAL_CMD_LIST =
      // @ts-ignore-next-line
      TERMINAL_COMMANDS["projects"][id]["display-sections"][
        displaySectionIndex
      ]["terminals"];

    const [currentTerminalTyping, setCurrentTerminalTyping] = useState(
      defaultCurrentTerminalTyping
    );
    const [shouldTerminalDisplayBeRunning, setShouldTerminalDisplayBeRunning] =
      useState(false);
    const [currentTypewriterInstance, setCurrentTypewriterInstance] =
      useState<TypewriterClass>();

    const [isVideoLoading, setIsVideoLoading] = useState(true);

    const handleStartCursorBlink = useCallback(
      (terminalIndex: number, cursorIndex: number = 1) => {
        const terminalElement = document.querySelector(
          `#terminal-display-${displaySectionIndex}-console-${terminalIndex} #terminal-typewriter-${cursorIndex}`
        );

        const typewriter = new TypewriterClass(terminalElement, {
          cursorClassName: `${defaultTypewriterCursorClass} terminal-cursor-style terminal-typewriter-cursor-${cursorIndex}`,
          cursor: "❚",
        });

        typewriter.typeString("").start();
      },
      [displaySectionIndex]
    );

    React.useEffect(() => {
      for (let i = 1; i < numOfTerminal + 1; i++) {
        handleStartCursorBlink(i);
      }
    }, [numOfTerminal, handleStartCursorBlink]);

    const execCallbackFn = useCallback(() => {
      if (typeof callbackFnFromTerminalJSON !== "undefined") {
        callbackFnFromTerminalJSON();
      }
    }, [callbackFnFromTerminalJSON]);

    const handleStartVideo = () => {
      if (videoRef.current) {
        //@ts-ignore-next-line
        videoRef.current.play();
      }
    };

    const handleResetVideo = () => {
      if (videoRef.current) {
        //@ts-ignore-next-line
        videoRef.current.pause();
        //@ts-ignore-next-line
        videoRef.current.currentTime = 0;
      }
    };

    const handleInsertTerminalOutput = useCallback(
      (
        terminalIndex: number,
        output: string,
        insertPositionRelativeToLastChild?: "before" | "after"
      ) => {
        const terminal = document.querySelector(
          `#terminal-display-${displaySectionIndex}-console-${terminalIndex}`
        );

        const outputHTMLString = `<div style="line-height:1;color:whitesmoke;white-space:pre-wrap;">${output}</div>`;

        //
        // terminal has no output yet
        //
        if (
          (terminal?.childNodes.length ?? 0) === 1 ||
          insertPositionRelativeToLastChild === "after"
        ) {
          terminal?.insertAdjacentHTML("beforeend", outputHTMLString);

          //
        } else {
          const lastChild = terminal?.lastChild;

          if (typeof lastChild === "undefined" || !lastChild) return;

          const newOutputElement =
            stringHelper.createElementFromHTML(outputHTMLString);

          if (!newOutputElement) return;

          terminal?.insertBefore(newOutputElement, lastChild);
        }
      },
      [displaySectionIndex]
    );

    const handleTerminateProgram = (
      terminalIndex: number,
      cursorIndex: number
    ) => {
      const terminal = document.querySelector(
        `#terminal-display-${displaySectionIndex}-console-${terminalIndex}`
      );

      document
        .querySelector(
          `#terminal-display-${displaySectionIndex}-console-${terminalIndex} .terminal-typewriter-cursor-${cursorIndex}`
        )
        ?.remove();

      const newCursorIndex = cursorIndex + 1;

      const terminalData = TERMINAL_CMD_LIST[terminalIndex - 1];
      const shellName = terminalData["shell-name"];

      const shell = retrieveTerminalShell(newCursorIndex, shellName);

      terminal?.insertAdjacentHTML(
        "beforeend",
        ReactDOMServer.renderToString(shell)
      );

      handleStartCursorBlink(terminalIndex, newCursorIndex);
    };

    const isTerminateControlElementPresent = () => {
      const terminate = document
        .querySelector("#control")
        ?.classList.contains("terminate");

      return terminate ?? false;
    };

    const handleStartCountdownCallback = useCallback(
      (timeoutSeconds: number, callback: () => void) => {
        setTimeout(() => {
          const terminate = isTerminateControlElementPresent();

          if (terminate) return;

          callback();
        }, timeoutSeconds * 1000);
      },
      []
    );

    const retrieveTerminalConsoleDisplay = (consoleIndex: number) => {
      return (
        <div
          className="terminal-console-display"
          style={{
            width: "100%",
            maxWidth: "100%",
            height: "14rem",
            backgroundColor: "black",
            borderRadius: "1rem",
            padding: "0.5rem",
            paddingRight: "1rem",
            marginTop: "1rem",
            borderWidth: "0.1rem",
            borderColor: "#6E2E2E",
            borderStyle: "solid",
            // overflowX: "scroll",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              // maxWidth: "100%",
            }}
          >
            <pre
              id={`terminal-display-${displaySectionIndex}-console-${
                consoleIndex + 1
              }`}
              className="terminal-text-style"
              style={{
                // whiteSpace: "pre-wrap",
                // wordBreak: "break-word",
                // wordWrap: "break-word",

                whiteSpace: "nowrap",
                wordBreak: "keep-all",
                wordWrap: "unset",

                width: "100%",
                height: "100%",
                lineHeight: 1.5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingRight: "0.5rem",
                  }}
                >
                  <p
                    style={{
                      color: "green",
                      fontWeight: "bold",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {typeof TERMINAL_CMD_LIST[consoleIndex]["shell-name"] ===
                    "undefined"
                      ? "user@terminal"
                      : TERMINAL_CMD_LIST[consoleIndex]["shell-name"]}
                  </p>
                  <p
                    style={{
                      color: "whitesmoke",
                      fontWeight: "bold",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    :
                  </p>
                  <p
                    style={{
                      color: "#3666F5",
                      fontWeight: "bold",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    ~
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      padding: 0,
                      margin: 0,
                      color: "whitesmoke",
                    }}
                  >
                    $
                  </p>
                </div>

                <div id="terminal-typewriter-1" />
              </div>
            </pre>
          </div>
        </div>
      );
    };

    const retrieveTerminalShell = useCallback(
      (typewriterIndex: number, shellName?: string) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingRight: "0.5rem",
              }}
            >
              <p
                style={{
                  color: "green",
                  fontWeight: "bold",
                  padding: 0,
                  margin: 0,
                }}
              >
                {typeof shellName === "undefined" ? "user@terminal" : shellName}
              </p>

              <p
                style={{
                  color: "whitesmoke",
                  fontWeight: "bold",
                  padding: 0,
                  margin: 0,
                }}
              >
                :
              </p>
              <p
                style={{
                  color: "#3666F5",
                  fontWeight: "bold",
                  padding: 0,
                  margin: 0,
                }}
              >
                ~
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  padding: 0,
                  margin: 0,
                  color: "whitesmoke",
                }}
              >
                $
              </p>
            </div>

            <div id={`terminal-typewriter-${typewriterIndex}`} />
          </div>
        );
      },
      []
    );

    const renderInfiniteCommand = useCallback(
      async (
        display: HTMLElement | null,
        output: string
        // shouldType: boolean
        // retrieveShouldType?: () => boolean
      ) => {
        do {
          //
          // 'terminate' className is enabled on control div element
          //
          const terminate = isTerminateControlElementPresent();

          if (terminate) return;

          display?.insertAdjacentHTML(
            "beforeend",
            `<div style="line-height:1;color:whitesmoke">${output}</div>`
          );

          await new Promise((res) => setTimeout(res, 2000));
        } while (1);
      },
      []
    );

    const terminalCommandTypedFinishResponse = useCallback(
      async (
        {
          output,
          isInfiniteOutput,
          isProgramRunning,
          shouldExecCallbackOnFinish,
          shouldPromptForSudoPassword,
        }: Command,
        terminalIndex: number
      ) => {
        document
          .querySelector(
            `#terminal-display-${displaySectionIndex}-console-${terminalIndex} .terminal-typewriter-cursor-1`
          )
          ?.remove();

        const display = document.getElementById(
          `terminal-display-${displaySectionIndex}-console-${terminalIndex}`
        );

        const terminalData = TERMINAL_CMD_LIST[terminalIndex - 1];

        const commands =
          /// @ts-ignore-next-line
          terminalData["commands"];

        //
        // Display sudo password prompt
        //
        if (shouldPromptForSudoPassword) {
          handleInsertTerminalOutput(
            terminalIndex,
            "[sudo] password for user: ..."
          );

          await new Promise((res) => setTimeout(res, 1500));
        }

        //
        // EXEC callback
        //
        if (shouldExecCallbackOnFinish) {
          execCallbackFn();
        }

        if (isInfiniteOutput) {
          renderInfiniteCommand(display, output);
        } else {
          if (output !== "") {
            display?.insertAdjacentHTML(
              "beforeend",
              `<div style="line-height:1;color:whitesmoke">${output}</div>`
            );
          }

          //
          // if this is true, indicates that there is only one command to be executed - since this scope would return anyway
          //
          if (isProgramRunning) {
            display?.insertAdjacentHTML(
              "beforeend",
              '<div id="terminal-typewriter-2" />'
            );

            handleStartCursorBlink(terminalIndex, 2);

            setCurrentTerminalTyping(terminalIndex + 1);

            return;
          }

          const shellName = terminalData["shell-name"];
          const shell = retrieveTerminalShell(2, shellName);

          display?.insertAdjacentHTML(
            "beforeend",
            ReactDOMServer.renderToString(shell)
          );

          //
          // Final command
          //
          if (commands.length === 1) {
            handleStartCursorBlink(terminalIndex, 2);

            setCurrentTerminalTyping(terminalIndex + 1);

            return;
          }
        }

        let index;

        for (index = 1; index < commands.length; index++) {
          const command = commands[index];

          const typewriterIndex = index + 1;

          const terminalTypewriter = document.querySelector(
            `#terminal-display-${displaySectionIndex}-console-${terminalIndex} #terminal-typewriter-${typewriterIndex}`
          );

          const typewriter = new TypewriterClass(terminalTypewriter, {
            cursorClassName: `${defaultTypewriterCursorClass} terminal-cursor-style terminal-typewriter-cursor-${typewriterIndex}`,
            cursor: "❚",
          });

          setCurrentTypewriterInstance(typewriter);

          await new Promise((res) => {
            typewriter
              .typeString(command.input)
              .pauseFor(1000)
              .callFunction(async () => {
                //
                // to wait for explicit call to start typing the command
                //
                if (command.shouldPauseExecution) {
                  //
                  // EXEC callback
                  //
                  if (command.shouldExecCallbackOnFinish) {
                    execCallbackFn();
                  }

                  return;
                }

                const isFinalCommand = typewriterIndex === commands.length;

                document
                  .querySelector(
                    `#terminal-display-${displaySectionIndex}-console-${terminalIndex} .terminal-typewriter-cursor-${typewriterIndex}`
                  )
                  ?.remove();

                //
                // Display sudo password prompt
                //
                if (command.shouldPromptForSudoPassword) {
                  handleInsertTerminalOutput(
                    terminalIndex,
                    "[sudo] password for user: ...",
                    "after"
                  );

                  await new Promise((res) => setTimeout(res, 1500));
                }

                //
                // EXEC callback
                //
                if (command.shouldExecCallbackOnFinish) {
                  execCallbackFn();
                }

                if (command.isInfiniteOutput) {
                  renderInfiniteCommand(display, command.output);
                } else {
                  const output = command.output;

                  if (output !== "") {
                    display?.insertAdjacentHTML(
                      "beforeend",
                      `<div style="line-height:1;color:whitesmoke">${command.output}</div>`
                    );
                  }

                  if (command.isProgramRunning) {
                    display?.insertAdjacentHTML(
                      "beforeend",
                      `<div id="terminal-typewriter-${typewriterIndex + 1}" />`
                    );

                    handleStartCursorBlink(terminalIndex, typewriterIndex + 1);

                    setCurrentTerminalTyping(terminalIndex + 1);

                    return;
                  }

                  const shellName = terminalData["shell-name"];

                  const shell = retrieveTerminalShell(
                    typewriterIndex + 1,
                    shellName
                  );

                  display?.insertAdjacentHTML(
                    "beforeend",
                    ReactDOMServer.renderToString(shell)
                  );

                  if (isFinalCommand) {
                    handleStartCursorBlink(terminalIndex, typewriterIndex + 1);

                    setCurrentTerminalTyping(terminalIndex + 1);

                    return;
                  }
                }

                res(200);
              })
              .pauseFor(1000)
              .start();
          });
        }
      },

      [
        retrieveTerminalShell,
        renderInfiniteCommand,
        execCallbackFn,
        TERMINAL_CMD_LIST,
        displaySectionIndex,
        handleInsertTerminalOutput,
        handleStartCursorBlink,
      ]
    );

    const handleExecuteCommand = async (
      terminalIndex: number,
      index: number
    ) => {
      const terminalData = TERMINAL_CMD_LIST[terminalIndex - 1];

      const commands =
        /// @ts-ignore-next-line
        terminalData["commands"];

      const command = commands[index - 1];

      const isFinalCommand = index === commands.length;

      const display = document.getElementById(
        `terminal-display-${displaySectionIndex}-console-${terminalIndex}`
      );

      document
        .querySelector(
          `#terminal-display-${displaySectionIndex}-console-${terminalIndex} .terminal-typewriter-cursor-${index}`
        )
        ?.remove();

      //
      // Display sudo password prompt
      //
      if (command.shouldPromptForSudoPassword) {
        handleInsertTerminalOutput(
          terminalIndex,
          "[sudo] password for user: ...",
          "after"
        );

        await new Promise((res) => setTimeout(res, 1500));
      }

      if (command.isInfiniteOutput) {
        renderInfiniteCommand(display, command.output);
      } else {
        const output = command.output;

        if (output !== "") {
          display?.insertAdjacentHTML(
            "beforeend",
            `<div style="line-height:1;color:whitesmoke">${command.output}</div>`
          );
        }

        if (command.isProgramRunning) {
          display?.insertAdjacentHTML(
            "beforeend",
            `<div id="terminal-typewriter-${index + 1}" />`
          );

          handleStartCursorBlink(terminalIndex, index + 1);

          setCurrentTerminalTyping(terminalIndex + 1);

          return;
        }

        const shellName = terminalData["shell-name"];
        const shell = retrieveTerminalShell(index + 1, shellName);

        display?.insertAdjacentHTML(
          "beforeend",
          ReactDOMServer.renderToString(shell)
        );

        if (isFinalCommand) {
          handleStartCursorBlink(terminalIndex, index + 1);

          setCurrentTerminalTyping(terminalIndex + 1);

          return;
        }
      }
    };

    //
    // DIRECT FUNCTION calls from parent component
    //
    React.useImperativeHandle(ref, () => {
      return {
        executeCommand(terminalIndex: number, index: number) {
          handleExecuteCommand(terminalIndex, index);
        },
        insertTerminalOutput(
          terminalIndex: number,
          output: string,
          callback?: () => void
        ) {
          handleInsertTerminalOutput(terminalIndex, output);
          if (typeof callback !== "undefined") {
            callback();
          }
        },
        terminateProgram(terminalIndex: number, cursorIndex: number) {
          handleTerminateProgram(terminalIndex, cursorIndex);
        },
        startVideo() {
          handleStartVideo();
        },
        startCountdownCallback(timeoutSeconds: number, callback: () => void) {
          handleStartCountdownCallback(timeoutSeconds, callback);
        },
      };
    });

    const startTypingOnTerminal = useCallback(
      (terminalIndex: number) => {
        const terminalCmdListLen = TERMINAL_CMD_LIST.length;

        if (terminalIndex > terminalCmdListLen) {
          return;
        }

        const terminalElement = document.querySelector(
          `#terminal-display-${displaySectionIndex}-console-${terminalIndex} #terminal-typewriter-1`
        );

        const typewriter = new TypewriterClass(terminalElement, {
          cursorClassName: `${defaultTypewriterCursorClass} terminal-cursor-style
   terminal-cursor-style terminal-typewriter-cursor-1`,
          cursor: "❚",
        });

        setCurrentTypewriterInstance(typewriter);

        typewriter
          .typeString(
            TERMINAL_CMD_LIST[terminalIndex - 1]["commands"][0]["input"]
          )
          .pauseFor(1000)
          .callFunction(() =>
            terminalCommandTypedFinishResponse(
              TERMINAL_CMD_LIST[terminalIndex - 1]["commands"][0],
              terminalIndex
            )
          )
          .pauseFor(1000)
          .start();
      },
      [
        terminalCommandTypedFinishResponse,
        TERMINAL_CMD_LIST,
        displaySectionIndex,
      ]
    );

    //
    // START typing on specified terminal index
    //
    React.useEffect(() => {
      if (!shouldTerminalDisplayBeRunning) return;

      startTypingOnTerminal(currentTerminalTyping);
    }, [
      currentTerminalTyping,
      startTypingOnTerminal,
      shouldTerminalDisplayBeRunning,
    ]);

    const startTerminalTyping = async () => {
      // already started
      if (shouldTerminalDisplayBeRunning) return;

      if (shouldStartVideoOnPlayButtonClick) {
        if (videoRef.current) {
          //@ts-ignore-next-line
          videoRef.current.play();
        }
      }

      //
      // REMOVE terminate class from CONTROL ELEMENT to allow infinite commands to run - see renderInfiniteCommand()
      //
      document.querySelector("#control")?.classList.remove("terminate");

      setShouldTerminalDisplayBeRunning(true);
    };

    const terminateTerminalTyping = () => {
      // already terminated
      if (!shouldTerminalDisplayBeRunning) return;

      //
      // stop video
      //
      handleResetVideo();

      //
      // stop typing
      //
      currentTypewriterInstance.stop();

      const terminalCmdListLen = TERMINAL_CMD_LIST.length;

      for (let i = 0; i < terminalCmdListLen; i++) {
        //
        // CLEAR ALL ITEMS on terminal display
        //
        const display = document.querySelector(
          `#terminal-display-${displaySectionIndex}-console-${i + 1}`
        );

        while (display?.firstChild) {
          if (!display.lastChild) continue;

          display.removeChild(display.lastChild);
        }

        const terminalData = TERMINAL_CMD_LIST[i];
        const shellName = terminalData["shell-name"];

        const shell = retrieveTerminalShell(1, shellName);

        display?.insertAdjacentHTML(
          "beforeend",
          ReactDOMServer.renderToString(shell)
        );

        //
        handleStartCursorBlink(i + 1, 1);
      }

      setShouldTerminalDisplayBeRunning(false);
      setCurrentTerminalTyping(defaultCurrentTerminalTyping);
      setCurrentTypewriterInstance(undefined);

      //
      // ADD CONTROL ELEMENT CLASS to stop infinite commands - see renderInfiniteCommand()
      //

      document.querySelector("#control")?.classList.add("terminate");
    };

    return (
      <Container fluid>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div id="control" />

          <div
            style={{
              width: "3rem",
              height: "3rem",
              backgroundColor: "silver",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className={`terminal-action-icon${
              shouldTerminalDisplayBeRunning ? "-progress" : ""
            }`}
            onClick={startTerminalTyping}
          >
            <StartIcon color={"#6E2E2E"} size={28} />
          </div>

          <div style={{ margin: "0.3rem" }} />

          <div
            style={{
              width: "3rem",
              height: "3rem",
              backgroundColor: "silver",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className={`terminal-action-icon${
              !shouldTerminalDisplayBeRunning ? "-disabled" : ""
            }`}
            onClick={terminateTerminalTyping}
          >
            <TrashIcon color={"#6E2E2E"} size={24} />
          </div>
        </div>

        {typeof videoDisplayURI === "undefined" ? (
          <Row xs={1} sm={1} md={2} lg={2} xl={2}>
            {Array.from(Array(numOfTerminal).keys()).map((consoleIndex) => (
              <Col key={consoleIndex}>
                {retrieveTerminalConsoleDisplay(consoleIndex)}
              </Col>
            ))}
          </Row>
        ) : (
          <>
            <Row>
              <Col xl={4}>
                {Array.from(Array(numOfTerminal).keys()).map((consoleIndex) => (
                  <Row key={consoleIndex}>
                    {retrieveTerminalConsoleDisplay(consoleIndex)}
                  </Row>
                ))}
              </Col>

              <Col xl={8}>
                {[videoDisplayURI].map((video, index) => (
                  <div
                    key={index}
                    className="video-player-container mb2"
                    style={{
                      width: "100%",
                      borderRadius: "1rem",
                      padding: "0.5rem",
                      paddingRight: "1rem",
                      marginTop: "1rem",
                      borderWidth: "0.1rem",
                      borderColor: "#6E2E2E",
                      borderStyle: "solid",
                      position: "relative",
                    }}
                  >
                    <video
                      className="video-player"
                      style={{
                        display: isVideoLoading ? "none" : "unset",
                      }}
                      src={window.location.pathname.replace(/\/$/, "") + video}
                      // src={
                      //   shouldLoadVideo
                      //     ? window.location.pathname.replace(/\/$/, "") + video
                      //     : undefined
                      // }
                      onLoadedData={() => setIsVideoLoading(false)}
                      muted
                      loop={false}
                      ref={videoRef}
                    />

                    <div
                      style={{
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                ))}
              </Col>
            </Row>
          </>
        )}
      </Container>
    );
  }
);

export default TerminalConsoleDisplay;
