import { Container, Row } from "react-bootstrap";
import { FiLink } from "react-icons/fi";

import MainPageJSONData from "../../data/main-page.json";

const Citations = () => {
  return (
    <Container fluid className="container-style pb8">
      <Row className="header-padding">
        <h1 className="header-text">Citations</h1>
      </Row>

      {MainPageJSONData.citations.map((citation, index) => (
        <Row key={index} className="row-padding">
          <FiLink size="1.4rem" color="gray" />

          <a
            className="text-style-small"
            style={{ cursor: "pointer", marginLeft: 8 }}
            target="_blank"
            rel="noreferrer"
            href={citation}
          >
            {citation}
          </a>
        </Row>
      ))}
    </Container>
  );
};

export default Citations;
